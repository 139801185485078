<template>
  <v-container>
    <!-- Enrolled students count -->
    <v-scroll-x-reverse-transition hide-on-leave leave-absolute>
      <v-row v-if="enrolledStudents.show">
        <v-col cols="12" md="6">
          <v-card class="white rounded-lg elevation-0 pa-5">
            <IconCard
              :title="`${enrolledStudents.count}`"
              :subTitle="`Estudiantes activos`"
              icon="account-outline"
              colorTheme="primary"
            ></IconCard>
          </v-card>
        </v-col>
        <v-col cols="12">
          <SubEvaluationPendingWidget
            v-if="
              existInArray('Widget evaluaciones pendientes', currentPageActions)
            "
          />
          <!-- <SubEvaluationPendingWidget v-if="true"/> -->
        </v-col>
      </v-row>
    </v-scroll-x-reverse-transition>

    <!-- failed jobs  -->
    <v-sheet
      v-if="false"
      elevation="0"
      class="white rounded-lg d-flex py-3 mb-10"
    >
      <v-row class="">
        <!-- LARAVEL FAILED JOBS COUNT -->
        <v-col cols="12" md="6" xl="4" class="white rounded-lg rounded-r-md-0">
          <div v-if="laravelStoppedJobsWidget.isLoading">
            <v-skeleton-loader
              type="list-item-avatar-three-line"
            ></v-skeleton-loader>
          </div>
          <div v-else>
            <div v-if="laravelStoppedJobsWidget.count > 0" class="pa-5 d-flex">
              <div class="rounded-circle my-4 pa-5 red lighten-4">
                <v-icon color="red">mdi-alert-octagon-outline</v-icon>
              </div>
              <div class="d-flex justify-center flex-column ml-5">
                <p class="text-overline ma-0 font-weight-bold red--text">
                  GE-API
                </p>
                <p class="ma-0 text-h5 font-weight-bold">
                  {{ laravelStoppedJobsWidget.count }}
                </p>
                <p class="text-overline ma-0">Jobs detenidos</p>
              </div>
            </div>
            <div v-else class="white rounded-lg pa-5 d-flex">
              <div class="rounded-circle my-4 pa-5 green lighten-4">
                <v-icon color="success">mdi-check-circle-outline</v-icon>
              </div>
              <div class="d-flex justify-center flex-column ml-5">
                <p class="text-overline ma-0 font-weight-bold red--text">
                  GE-API
                </p>
                <p class="ma-0 text-h5 font-weight-bold">¡Todo bien!</p>
                <p class="text-overline ma-0">Sin jobs detenidos</p>
              </div>
            </div>
          </div>
        </v-col>

        <!-- SEQUELIZE FAILED JOBS COUNT -->
        <v-col
          v-if="
            existInArray(
              'Ver procesos que no se ejecutaron',
              currentPageActions
            )
          "
          cols="12"
          md="6"
          xl="4"
          class="white rounded-lg rounded-l-md-0"
        >
          <div v-if="sequelizeStoppedJobsWidget.isLoading">
            <v-skeleton-loader
              type="list-item-avatar-three-line"
            ></v-skeleton-loader>
          </div>
          <div v-else>
            <div
              v-if="sequelizeStoppedJobsWidget.count > 0"
              class="pa-5 d-flex"
            >
              <div class="rounded-circle my-4 pa-5 red lighten-4">
                <v-icon color="red">mdi-alert-octagon-outline</v-icon>
              </div>
              <div class="d-flex justify-center flex-column ml-5">
                <p class="text-overline ma-0 font-weight-bold blue--text">
                  DP-API
                </p>
                <p class="ma-0 text-h5 font-weight-bold">
                  {{ sequelizeStoppedJobsWidget.count }}
                </p>
                <p class="text-overline ma-0">Jobs detenidos</p>
              </div>
            </div>
            <div v-else class="white rounded-lg pa-5 d-flex">
              <div class="rounded-circle my-4 pa-5 green lighten-4">
                <v-icon color="success">mdi-check-circle-outline</v-icon>
              </div>
              <div class="d-flex justify-center flex-column ml-5">
                <p class="text-overline ma-0 font-weight-bold blue--text">
                  DP-API
                </p>
                <p class="ma-0 text-h5 font-weight-bold">¡Todo bien!</p>
                <p class="text-overline ma-0">Sin jobs detenidos</p>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-sheet>

    <!-- Notificación de estudiantes con más de 5 ausencias -->
    <template
      v-if="
        existInArray('Ver listado estudiantes ausentes', currentPageActions)
      "
    >
      <v-scroll-x-reverse-transition hide-on-leave leave-absolute>
        <div class="d-flex mt-4">
          <p class="font-weight-bold text-h5 mr-4">
            Estudiantes con 5 o más ausencias
          </p>
          <v-divider></v-divider>
        </div>
      </v-scroll-x-reverse-transition>
      <v-row class="mb-2">
        <v-col cols="12" md="6">
          <v-scroll-x-reverse-transition hide-on-leave leave-absolute>
            <v-row>
              <v-col cols="12">
                <v-card class="white rounded-lg elevation-0 pa-5">
                  <IconCardWithAction
                    v-if="!studentAbsences.isLoading"
                    colorTheme="red"
                    icon="account-cancel-outline"
                    :title="`${studentAbsences.data.length} estudiantes`"
                    :subTitle="`Tienen 5 o más ausencias`"
                    :loading="studentAbsences.isLoading"
                    buttonLegend="Detalle"
                    @action="openStudentAbsencesModal"
                  ></IconCardWithAction>
                </v-card>
              </v-col>
            </v-row>
          </v-scroll-x-reverse-transition>
        </v-col>
      </v-row>
    </template>
    <!-- Event assistance -->

    <v-scroll-x-reverse-transition hide-on-leave leave-absolute>
      <div v-if="enrolledStudents.show" class="d-flex">
        <p class="font-weight-bold text-h5 mr-4">Día de la familia</p>
        <v-divider></v-divider>
      </div>
    </v-scroll-x-reverse-transition>

    <v-row>
      <v-col cols="12" md="6">
        <v-scroll-x-reverse-transition hide-on-leave leave-absolute>
          <v-row v-if="enrolledStudents.show">
            <v-col cols="12">
              <v-card class="white rounded-lg elevation-0 pa-5">
                <IconCard
                  v-if="!eventStatsStudents.isLoadingStudents"
                  colorTheme="deep-orange"
                  icon="account-badge-outline"
                  :title="`${eventStatsStudents.totalStudents?.arrived} estudiantes`"
                  :subTitle="`han asistido de ${enrolledStudents.count} en total.`"
                >
                </IconCard>
              </v-card>
            </v-col>
          </v-row>
        </v-scroll-x-reverse-transition>
      </v-col>
      <v-col cols="12" md="6">
        <v-scroll-x-reverse-transition hide-on-leave leave-absolute>
          <v-row v-if="enrolledStudents.show">
            <v-col cols="12">
              <v-card class="white rounded-lg elevation-0 pa-5">
                <IconCard
                  v-if="!eventStatsPeople.isLoadingPeople"
                  colorTheme="purple"
                  icon="account-group-outline"
                  :title="`${eventStatsPeople.totalPeople?.data?.total} personas`"
                  :subTitle="`han asistido al evento del día de la familia.`"
                >
                </IconCard>
              </v-card>
            </v-col>
          </v-row>
        </v-scroll-x-reverse-transition>
      </v-col>
    </v-row>

    <!-- chart -->
    <v-row>
      <v-col v-if="false" cols="12" md="9" xl="6" class="white rounded-lg pa-5">
        <v-row>
          <v-col cols="12" lg="6">
            <v-autocomplete
              dense
              label="Docente"
              outlined
              hide-details
              :items="this.teachers"
              item-text="first_name"
              item-value="id_user"
              v-model="teacher.filters.teacherId"
              @click="chartReset()"
            >
              <!-- begin::selected student item -->
              <template v-slot:selection="{ item, index }">
                <v-chip color="primary" small v-if="index === 0">
                  <span>{{ item.first_name }} {{ item.last_name }}</span>
                </v-chip>
              </template>
              <!-- end::selected student item -->
              <template v-slot:item="{ item }">
                <v-list-item-content
                  class="mb-0 py-0 d-flex flex-column align-star justify-start"
                >
                  <v-list-item-title class="mt-4">
                    <span class="font-weight-medium text-start">
                      {{ item.first_name }} {{ item.last_name }}
                    </span>
                  </v-list-item-title>
                  <v-list-item-subtitle class="mt-1">
                    <span>
                      {{ item.user_type }}
                    </span>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle class="mt-1">
                    <span>
                      <strong class="ml-1">{{ item.email }}</strong>
                    </span>
                  </v-list-item-subtitle>

                  <v-divider class="mb-0 pb-0"></v-divider>
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" lg="6">
            <!-- begin::stage combo -->

            <v-select
              dense
              :label="stages.isLoading ? 'Cargando etapas...' : 'Etapa'"
              :loading="stages.isLoading"
              :disabled="stages.isLoading"
              outlined
              hide-details
              :items="stages.data"
              item-text="name"
              item-value="id"
              v-model="teacher.filters.stageId"
              @change="loadChartData()"
            >
              <!-- begin::selected stage item -->
              <template v-slot:selection="{ item, index }">
                <v-chip color="primary" small v-if="index === 0">
                  <span>{{ item.name }}</span>
                </v-chip>
              </template>
              <!-- end::selected stage item -->
            </v-select>
            <!-- end::stage combo -->
          </v-col>
          <!-- total de intervenciones -->
        </v-row>
        <v-row v-if="canGenerateChart">
          <!-- total -->
          <v-col cols="12" class="ma-0 pa-0 mt-4">
            <div v-if="!teacherRequestsBySubject.isLoading">
              <div class="pa-4 d-flex">
                <div class="rounded-circle pa-5 blue lighten-4">
                  <v-icon color="blue">mdi-set-none</v-icon>
                </div>
                <div class="d-flex justify-center flex-column ml-5">
                  <p class="ma-0 text-h5 font-weight-bold">
                    {{ teacherRequestsBySubject.total }}
                  </p>
                  <p class="text-overline ma-0">cambios en total</p>
                </div>
              </div>
            </div>
            <div v-else>
              <v-skeleton-loader
                type="list-item-avatar-three-line"
              ></v-skeleton-loader>
            </div>
          </v-col>
          <v-container v-if="teacherRequestsBySubject.total > 0">
            <!-- chart -->
            <v-col
              v-if="!teacherRequestsBySubject.isLoading"
              class="d-none d-sm-inline"
              cols="12"
            >
              <div>
                <BarChart
                  :data="teacherRequestsBySubject.data"
                  :selectedTeacher="
                    teacherRequestsBySubject.selectedTeacher.first_name
                  "
                  :selectedStage="teacherRequestsBySubject.selectedStage.name"
                ></BarChart>
              </div>
            </v-col>
            <!-- fallback tabla -->
            <v-col
              v-if="!teacherRequestsBySubject.isLoading"
              cols="12"
              class="d-sm-none pa-0"
            >
              <v-data-table
                :headers="teacherRequestsBySubject.headers"
                :items="teacherRequestsBySubject.data"
                hide-default-footer
                class="elevation-1"
              ></v-data-table>
            </v-col>
            <!-- else -->
            <v-col v-else>
              <v-skeleton-loader type="image, actions"></v-skeleton-loader>
            </v-col>
          </v-container>
        </v-row>
        <v-row v-else>
          <v-col cols="12">
            <v-row class="d-flex flex-column">
              <v-col cols="4" sm="2" class="mx-auto">
                <img
                  style="width: 100%"
                  src="../../../public/media/bg/analyzing.png"
                  alt=""
                />
              </v-col>
              <p class="text-center mx-4">
                Selecione un <span class="font-weight-bold">docente</span> y una
                <span class="font-weight-bold">etapa</span> para ver el número
                de <br class="d-none d-sm-inline" />
                intervenciones en evaluaciones de la etapa seleccionada.
              </p>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <StudentAbsencesDashboardModal
      ref="mdlStudentAbsencesModal"
      :studentAbsences="studentAbsences.data"
    ></StudentAbsencesDashboardModal>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { FETCH_USERS } from "@/core/services/store/role.module.js";
import {
  GET_CURRENT_PAGE_ACTIONS,
  PURGE_CURRENT_PAGE_ACTIONS,
} from "@/core/services/store/role.module";
import stageRepository from "@/repositories/stageRepository";
import dashboardRepository from "../../repositories/dashboardRepository";
import studentClassRepository from "../../repositories/studentClassRepository";
import BarChart from "@/components/elements/stats/BarChart.vue";
import IconCard from "@/components/elements/dashboard/IconCard.vue";
import SubEvaluationPendingWidget from "../../components/elements/dashboard_widget/SubEvaluationPendingWidget.vue";
import StudentAbsencesDashboardModal from "../../components/modals/student_class/StudentAbsencesDashboardModal.vue";
import IconCardWithAction from '../../components/elements/dashboard/IconCardWithAction.vue';

export default {
  name: "dashboard",
  components: {
    IconCard,
    SubEvaluationPendingWidget,
    BarChart,
    StudentAbsencesDashboardModal,
    IconCardWithAction
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
    this.$store.dispatch(GET_CURRENT_PAGE_ACTIONS, "Inicio");
    this.$store.dispatch(FETCH_USERS);

    this.getEnrolledStudentCount();

    //this.loadStages();
    this.getEventStudentAssistanceStats();
    this.getEventPeopleAssistanceStats();
    this.loadStudentAbsences();
  },

  watch: {
    currentPageActions() {
      // this.getStoppedJobsCount();
    },
  },

  data() {
    return {
      enrolledStudents: {
        show: false,
        isLoading: false,
        count: 0,
      },
      eventStatsPeople: { isLoadingPeople: false },
      eventStatsStudents: { isLoadingStudents: false },
      teacherRequestsBySubject: {
        isLoading: false,
        data: [],
        selectedTeacher: "",
        selectedStage: "",
        total: 0,
        headers: [
          { text: "Asignatura", value: "x" },
          { text: "Correcciones", value: "correction" },
          { text: "Extensiones", value: "extension" },
        ],
      },
      studentAbsences: {
        data: [],
        isLoading: false,
      },
      laravelStoppedJobsWidget: {
        count: 0,
        isLoading: false,
      },
      sequelizeStoppedJobsWidget: {
        count: 0,
        isLoading: false,
      },
      teacher: {
        filters: {},
      },

      stages: {
        data: [],
        isLoading: true,
      },
    };
  },

  methods: {
    getEnrolledStudentCount() {
      this.enrolledStudents.isLoading = true;

      dashboardRepository
        .getEnrolledStudentCount()
        .then(({ data }) => {
          // console.log(data);
          this.enrolledStudents.show = true;
          this.enrolledStudents.count = data.count;
        })
        .catch((error) => {
          this.enrolledStudents.show = false;
          console.error(
            "No se pudo obtener el número de estudiante matrículados. ",
            error
          );
        })
        .finally(() => {
          this.enrolledStudents.isLoading = false;
        });
    },
    getEventPeopleAssistanceStats() {
      this.eventStatsPeople.isLoadingPeople = true;

      dashboardRepository
        .getTotalAssistanceToEvent()
        .then(({ data }) => {
          this.eventStatsPeople.totalPeople = data;
        })
        .catch((error) => {
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        })
        .finally(() => {
          setTimeout(() => {
            this.eventStatsPeople.isLoadingPeople = false;
          }, 1000);
        });
    },

    getEventStudentAssistanceStats() {
      this.eventStatsStudents.isLoadingStudents = true;

      dashboardRepository
        .getTotalAssistanceStudents()
        .then(({ data }) => {
          this.eventStatsStudents.totalStudents = data;
        })
        .catch((error) => {
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        })
        .finally(() => {
          this.eventStatsStudents.isLoadingStudents = false;
        });
    },

    getStoppedJobsCount() {
      this.laravelStoppedJobsWidget.isLoading = true;
      this.sequelizeStoppedJobsWidget.isLoading = true;

      let fetchGeApiStoppedJobs =
        dashboardRepository.getLaravelStoppedJobsCount();

      let fetchSDpApiStoppedJobs =
        dashboardRepository.getSequelizeStoppedJobsCount();

      Promise.all([fetchGeApiStoppedJobs, fetchSDpApiStoppedJobs])
        .then((responses) => {
          // this returns "conteo" key-value pair with the amount
          let GeApiJobs = responses[0].data;

          this.laravelStoppedJobsWidget.count = GeApiJobs.conteo;

          //in this case, this returns an array with the failed jobs.
          //so, the amount of failed jobs is the array length.
          let DpApiJobs = responses[1].data;
          this.sequelizeStoppedJobsWidget.count = DpApiJobs.data.length;
        })
        .catch((err) => {
          console.warn(
            "Error al obtener información del los jobs detenidos en las APIS",
            err
          );
        })
        .finally(() => {
          this.laravelStoppedJobsWidget.isLoading = false;
          this.sequelizeStoppedJobsWidget.isLoading = false;
        });

      // this.laravelStoppedJobsWidget.isLoading = true;
      // this.sequelizeStoppedJobsWidget.isLoading = true;

      // dashboardRepository
      //   .getLaravelStoppedJobsCount()
      //   .then(({ data }) => {
      //     this.laravelStoppedJobsWidget.count = data.conteo;
      //   })
      //   .catch(() => {
      //     this.fireToast({
      //       icon: "error",
      //       title: "No ha sido posible cargar los datos desde el servidor",
      //     });
      //   })
      //   .finally(() => {
      //     this.laravelStoppedJobsWidget.isLoading = false;
      //   });

      // dashboardRepository
      //   .getSequelizeStoppedJobsCount()
      //   .then(({ data }) => {
      //     this.sequelizeStoppedJobsWidget.count = data.length;
      //   })
      //   .catch(() => {
      //     this.fireToast({
      //       icon: "error",
      //       title: "No ha sido posible cargar los datos desde el servidor",
      //     });
      //   })
      //   .finally(() => {
      //     this.sequelizeStoppedJobsWidget.isLoading = false;
      //   });
    },

    loadStages() {
      this.stages.isLoading = true;
      stageRepository
        .getAllStages()
        .then((response) => {
          this.stages.data = response.data;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar el Widget 'Jobs detenidos'",
          });
        })
        .finally(() => {
          this.stages.isLoading = false;
        });
    },

    loadChartData() {
      this.teacherRequestsBySubject.isLoading = true;
      dashboardRepository
        .getEvaluationInterventionsByTeacher(
          this.teacher.filters.teacherId,
          this.teacher.filters.stageId
        )
        .then(({ data }) => {
          this.teacherRequestsBySubject.data = data.data;
          this.teacherRequestsBySubject.total = data.total;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        })
        .finally(() => {
          //filtering by grade id and getting the academic level id
          this.teacherRequestsBySubject.selectedTeacher = this.teachers.find(
            (teacher) => teacher.id_user === this.teacher.filters.teacherId
          );
          this.teacherRequestsBySubject.selectedStage = this.stages.data.find(
            (stage) => stage.id === this.teacher.filters.stageId
          );
          this.teacherRequestsBySubject.isLoading = false;
        });
    },

    loadStudentAbsences() {
      this.studentAbsences.isLoading = true;
      studentClassRepository
        .getStudentAbsencesDashboard()
        .then(({ data }) => {
          this.studentAbsences.data = data.data;
        })
        .catch((error) => {
          console.error(error);
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar las ausencias de los estudiantes",
          });
        })
        .finally(() => {
          this.studentAbsences.isLoading = false;
        });
    },
    openStudentAbsencesModal() {
      this.$refs.mdlStudentAbsencesModal.toggleModal();
    },
    chartReset() {
      this.teacher.filters = {};
    },
  },

  computed: {
    ...mapGetters(["currentPageActions"]),

    teachers() {
      let userTypes = [];
      userTypes.push("Docente académico");
      userTypes.push("Docente técnico");
      return this.$store.getters.getUsersByUserType(userTypes);
    },

    canGenerateChart() {
      return !!(this.teacher.filters.stageId && this.teacher.filters.teacherId);
    },
  },

  beforeDestroy() {
    this.$store.commit(PURGE_CURRENT_PAGE_ACTIONS);
  },
};
</script>

<style scoped>
.size {
  height: 100px;
  width: 100px;
}
</style>
