<template>
  <div>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card elevation="0">
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="close()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="close()"> Cerrar </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card elevation="0">
          <v-container>
            <v-row>
              <v-col cols="12">
                <div class="rounded-lg px-3 py-8">
                  <p class="text-center">
                    <v-chip>
                      <v-icon left>
                        mdi-account-supervisor-circle-outline
                      </v-icon>
                      <p class="mb-0 font-weight-bold">
                        Estudiantes con 5 o más inasistencias
                      </p>
                    </v-chip>
                  </p>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" xl="10" class="mx-auto">
                <v-data-table
                  :headers="headers"
                  :items="studentAbsences"
                  item-key="student_id"
                  show-expand
                  single-expand
                  class="elevation-1"
                >
                  <!-- Slot para filas expandidas -->
                  <template v-slot:expanded-item="{ item }">
                    <td :colspan="headers.length">
                      <v-container>
                        <v-list dense>
                          <v-subheader>FECHAS DE AUSENCIA</v-subheader>
                          <!-- Recorre cada fecha en fechas_inasistencias y la muestra en una lista -->
                          <v-list-item
                            v-for="(fecha, index) in item.fechas_inasistencias"
                            :key="index"
                          >
                            <v-list-item-content>
                              <v-list-item-title class="pa-1">{{
                                formatDate(fecha)
                              }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-container>
                    </td>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: "StudentAbsencesDashboardModal",
  props: {
    studentAbsences: {
      type: Array,
    },
  },
  data() {
    return {
      dialog: false,
      headers: [
        { text: "Código", value: "code" },
        { text: "Nombre", value: "first_name" },
        { text: "Apellido", value: "last_name" },
        { text: "Grado", value: "grade" },
        { text: "Especialidad", value: "speciality" },
        { text: "Días de ausencia", value: "dias_inasistencias" },
        { text: "", value: "data-table-expand" },
      ],
    };
  },
  mounted() {},
  methods: {
    toggleModal() {
      this.dialog = !this.dialog;
    },
    close() {
      this.toggleModal();
    },
    formatDate(date) {
      return this.fullFormattedDate(date, "dddd D [de] MMMM [del] YYYY");
    },
  },
  computed: {},
  watch: {
    dialog(newValue, oldValue) {},
  },
};
</script>
<style>
.v-text-field__details {
  display: none !important;
}
</style>
