<template>
  <v-row class="fill-height" align-content="center" justify="center">
    <v-col class="text-subtitle-1 text-center" cols="12">
      {{ text }}
    </v-col>
    <v-col cols="6">
      <v-progress-linear
        :olor="`${loadingBarColor} darken-1`"
        indeterminate
        rounded
        height="6"
      ></v-progress-linear>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "LoadingComponent",
  props: {
    loadingBarColor: {
      type: String,
      default: "blue",
    },
    text: {
      type: String,
      default: "Cargando contenido...",
    },
  },
};
</script>