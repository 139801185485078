import { PedagogicalDiaryService } from "@/core/services/apis.service";

const RESOURCE = "student-class";

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getAllStudentClass = () => {
  return PedagogicalDiaryService.get(`${RESOURCE}`);
};

/**
 * GET request to fetch a specific data
 * @param id
 * @returns {*}
 */
export const getStudentClass = (id) => {
  return PedagogicalDiaryService.get(`${RESOURCE}`, id);
};

/**
 * GET request to fetch a specific data
 * @param id
 * @returns {*}
 */
export const getAttendanceRecordsByDate = (date, attendance_id) => {
  return PedagogicalDiaryService.get(
    `${RESOURCE}/report/date/${date}/attendance-status-id/${attendance_id}`
  );
};

/**
 * GET request to fetch a specific data
 * @param id
 * @returns {*}
 */
export const checkExistStudentClass = (scheduleId, userId, date) => {
  return PedagogicalDiaryService.get(
    `${RESOURCE}/verifyStudentClass/schedule/${scheduleId}/user/${userId}/date/${date}`
  );
};

/**
 * GET request to fetch a specific data
 * @param id
 * @returns {*}
 */
export const getAttendanceList = (userId) => {
  return PedagogicalDiaryService.get(
    `${RESOURCE}/list-attendance/user/${userId}`
  );
};

/**
 * GET request to fetch a specific data
 * @param id
 * @returns {*}
 */
export const getAttendanceBySchedule = (scheduleId, userId, date) => {
  return PedagogicalDiaryService.get(
    `${RESOURCE}/attendanceRecord/schedule/${scheduleId}/user/${userId}/date/${date}`
  );
};

/**
 * POST request to create a new studentClass
 * @param payload
 * @returns {*}
 */
export const createStudentClass = (payload) => {
  return PedagogicalDiaryService.post(`${RESOURCE}/attendance`, payload);
};

/**
 * POST request to fetch student class info about students with absent and late arrival
 * @param payload
 * @returns {*}
 */
export const postStudentClassAndInfoForAbsentOrLateArrival = (payload) => {
  return PedagogicalDiaryService.post(
    `${RESOURCE}/getStudentClassByDateTimeAndCodes`,
    payload
  );
};

/**
 * POST request to fetch student class info about students with absent and late arrival
 * @param payload
 * @returns {*}
 */
export const postStudentClassAndInfoForLateArrivalToInstitution = (payload) => {
  return PedagogicalDiaryService.post(
    `${RESOURCE}/getLateArrivalToSchoolStudentClass`,
    payload
  );
};

/**
 * PUT request to update a specific studenClass
 * @param id
 * @param payload
 * @returns {*}
 */
export const updateStudentClass = (id, payload) => {
  return PedagogicalDiaryService.put(`${RESOURCE}`, id, payload);
};

/**
 * DELETE request to delete the specified studentClass
 * @param id
 * @returns {*}
 */
export const deleteStudentClass = (id) => {
  return PedagogicalDiaryService.delete(`${RESOURCE}/${id}`);
};

export const createLateArrivalRecordFromApprovedRequest = (payload) => {
  return PedagogicalDiaryService.post(
    `student-class/request/late-arrival`,
    payload
  );
};

export const deleteLateArrivalToClass = (id) => {
  return PedagogicalDiaryService.delete(`student-class/pass-attended/${id}`);
};

export const getStudentAbsencesDashboard = () => {
  return PedagogicalDiaryService.get(`${RESOURCE}/dashboard/absences-class`);
};

export default {
  getAllStudentClass,
  getStudentClass,
  createStudentClass,
  updateStudentClass,
  deleteStudentClass,
  checkExistStudentClass,
  getAttendanceBySchedule,
  postStudentClassAndInfoForAbsentOrLateArrival,
  postStudentClassAndInfoForLateArrivalToInstitution,
  getAttendanceList,
  createLateArrivalRecordFromApprovedRequest,
  deleteLateArrivalToClass,
  getAttendanceRecordsByDate,
  getStudentAbsencesDashboard,
};
