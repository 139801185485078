import ApiService from "@/core/services/api.service";
import { PedagogicalDiaryService } from "@/core/services/apis.service";

/**
 * GET request to get count of stopped jobs (WIDGET)
 * @returns {int}
 */
export const getLaravelStoppedJobsCount = () => {
  return ApiService.get("jobs/count");
};

/**
 * GET request to get count of stopped jobs (WIDGET)
 * @returns {int}
 */
export const getSequelizeStoppedJobsCount = () => {
  return PedagogicalDiaryService.get("failed-job");
};

/**
 * GET request to get count of stopped jobs (WIDGET)
 * @returns {int}
 */
export const getEnrolledStudentCount = () => {
  return ApiService.get("students/enrolled/count");
};

/**
 * GET request to get count of stopped jobs (WIDGET)
 * @returns {int}
 */
export const getEvaluationInterventionsByTeacher = (teacherId, StageId) => {
  return ApiService.get(`dashboard/request/user/${teacherId}/stage/${StageId}`);
};

//Get request to get registered students by gender
export const getRegisteredStudents = () => {
  return ApiService.get(`dashboard/student/registered`);
};

//events
export const getTotalAssistanceToEvent = () => {
  return ApiService.get(`family-day-records/amount-others`)
}
export const getTotalAssistanceStudents = () => {
  return ApiService.get(`family-day-records/had-arrived-from-total`)
}

export default {
  getLaravelStoppedJobsCount,
  getSequelizeStoppedJobsCount,
  getEvaluationInterventionsByTeacher,
  getRegisteredStudents,
  getTotalAssistanceToEvent,
  getTotalAssistanceStudents,
  getEnrolledStudentCount
};
