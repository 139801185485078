import ApiService from "@/core/services/api.service";


export const getSubEvaluationPendingByUser = (userId) => {
    return ApiService.get(`sub-evaluations-pending/${userId}`);
  };

export const getAllEvaluationPending = () => {
  return ApiService.get("sub-evaluations-pending");
}

export default {
  getSubEvaluationPendingByUser,
  getAllEvaluationPending
}