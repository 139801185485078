<template>
  <div
    class="chart-container main-container
mx-auto mx-md-0 "
  >
    <p class="text-h6 text-center">
      Extensiones y correciones de
      <span class="font-weight-bold">{{ this.selectedTeacher }}</span> en el
      <span class="font-weight-bold">{{ this.selectedStage }}</span>
    </p>

    <div class=" sizing ">
      <canvas
        id="myChart"
        aria-label="Contador de correcciones y extensiones de perfíl"
        role="img"
      ></canvas>
    </div>
  </div>
</template>

<script>
  import Chart from "chart.js/auto";
  import pattern from "patternomaly";
  export default {
    name: "BarChart",
    props: {
      selectedTeacher: {
        type: String,
      },
      selectedStage: {
        type: String,
      },
      data: {
        type: Array,
      },
    },
    data() {
      return {
        delayed: "",
      };
    },

    mounted() {
      const ctx = document.getElementById("myChart");
      const myChart = new Chart(ctx, {
        type: "bar",
        //Data
        data: {
          datasets: [
            {
              label: "Correcciones",
              data: this.data,
              parsing: {
                yAxisKey: "correction",
                stepSize: 5,
              },
              backgroundColor: [pattern.draw("dot", "#035397")],
              borderRadius: 4,
              maxBarThickness: 80,
              skipNull: true,
            },
            {
              label: "Extensiones",
              data: this.data,
              parsing: {
                yAxisKey: "extension",
                stepSize: 5,
              },
              backgroundColor: [pattern.draw("dash", "#112031")],
              borderRadius: 4,
              maxBarThickness: 80,
              skipNull: true,
            },
          ],
        },
        //Options and settings to show the chart
        options: {
          scales: {
            y: {
              // max: 100,
              // min: 0,
              ticks: {
                stepSize: 5,
              },
            },
          },
          animation: {
            onComplete: () => {
              this.delayed = true;
            },
            delay: (context) => {
              let delay = 0;
              if (
                context.type === "data" &&
                context.mode === "default" &&
                !this.delayed
              ) {
                delay = context.dataIndex * 400 + context.datasetIndex * 900;
              }
              return delay;
            },
          },
          responsive: true,
          plugins: {
            // title: {
            //   display: true,
            //   text: `Correcciones y extensiones de evaluaciones del docente ${this.selectedTeacher}`,
            // },
          },
        },
      });
      myChart;
    },
  };
</script>

<style scoped>
  .sizing {
    position: relative;
    width: 100%;
    padding: 2em;
    border-radius: 8px;
    background-color: #f8f8f8;
  }

  .main-container {
    width: 100%;
  }
</style>
