<template>
  <div>
    <v-sheet>
      <v-row justify="space-between" class="mx-lg-12 mx-0 mx-sm-8 pb-0 pt-5">
        <v-col cols="12" md="5" class="py-0">
          <h3 class="grey--text text--darken-3">
            Evaluaciones con notas pendientes de ingresar
          </h3>
        </v-col>
        <v-col cols="12" md="5" class="py-0" v-if="isCoordination">
          <v-text-field
            prepend-icon="mdi-magnify"
            @input="searchTeacher"
            placeholder="Busca docentes por nombre o correo"
            v-model="teacherFilterText"
          />
        </v-col>
      </v-row>
      <!-- Cargando contenido -->
      <LoadingComponent
        class="py-10"
        v-if="evaluationPending.isLoading && !wrongStatus"
      />

      <!-- Sin evaluaciones pendientes -->
      <IconCard
        v-if="emptyEvaluations"
        class="ml-0 ml-lg-12 ml-sm-10 pt-4"
        title="Sin evaluaciones pendientes"
        subTitle="No tienes evaluaciones con notas pendientes de ingresar"
        icon="check-underline-circle-outline"
        colorTheme="green"
      />

      <!-- Problemas al obtener el contenido -->
      <IconCard
        v-if="wrongStatus"
        :outlined="false"
        icon="exclamation"
        title="Problemas con el servidor"
        subTitle="No se ha podido obtener el contenido de las evaluaciones pendientes"
        colorTheme="red"
        class="ml-0 ml-lg-12 ml-sm-10 pb-5"
      />
      <!-- Listado de evaluaciones pendientes -->
      <v-slide-group
        :center-active="true"
        class="py-5"
        v-model="itemSelectedIndex"
        active-class="success"
        :show-arrows="showArrow"
        v-if="!evaluationPending.isLoading && !wrongStatus"
      >
        <v-slide-item
          v-for="evaluation in evaluationsPendingFiltered"
          :key="evaluation.key"
          v-slot="{ active, toggle }"
        >
          <v-card
            class="ma-2 px-3 pb-3"
            :color="active ? evaluation.color : 'white'"
            @click="toggle"
            elevation="1"
          >
            <!-- Titulo para coordinaciones -->
            <v-card-title
              class="d-flex justify-start pl-0 pb-2 mx-0"
              v-if="isCoordination"
            >
              {{
                `${evaluation.user_information?.first_name} ${evaluation.user_information?.last_name}`
              }}
            </v-card-title>

            <!-- Titulo para docentes -->
            <v-card-title
              class="d-flex justify-start pl-0 pb-2 mx-0"
              v-if="isTeacher"
            >
              {{ evaluation?.activity_type }}
              <v-chip class="ml-1" :color="evaluation.color">
                {{ evaluation?.subject?.code }}
              </v-chip>
            </v-card-title>
            <v-row>
              <v-col cols="12" class="d-flex flex-column">
                <!-- Información del docente (Coordinación)-->
                <span class="d-flex font-weight-medium" v-if="isCoordination">
                  <p class="font-weight-normal mb-2 grey--text text--darken-2">
                    {{ evaluation?.user_information?.email }}
                  </p>
                </span>
                <span
                  class="d-flex align-center font-weight-medium"
                  v-if="isCoordination"
                >
                  <v-chip class="mr-1 mb-1" :color="evaluation.color">
                    {{ evaluation?.subEvaluations.length }}
                  </v-chip>
                  Evaluaciones con notas pendientes
                </span>
                <!-- Información para docentes -->
                <span class="d-flex h6 font-weight-medium" v-if="isTeacher">
                  {{ evaluation?.subject?.grade }}
                </span>
                <span class="d-flex align-center font-weight-medium">
                  <v-chip class="mr-1" :color="evaluation.color">
                    {{ evaluation?.count }}
                  </v-chip>
                  Total estudiantes sin nota
                </span>
              </v-col>
            </v-row>
          </v-card>
        </v-slide-item>
      </v-slide-group>

      <!-- Contenido de la evaluación pendiente -->
      <v-expand-transition>
        <v-sheet v-if="itemSelectedIndex != null">
          <!-- Inicia contenido para docentes -->
          <v-row v-if="isTeacher" class="px-7">
            <!-- Inicio: Información de la subevaluación -->
            <v-col cols="12" class="pl-0 pb-1">
              <p class="font-weight-bold mb-1 h6">
                {{
                  evaluationsPendingFiltered[itemSelectedIndex]?.evaluation_type
                }}
              </p>
            </v-col>
            <v-col cols="12" md="7" class="pt-0 pl-md-0 pl-lg-0">
              <!-- Nombre de la asignatura o módulo-->
              <span class="font-weight-bold">
                {{
                  setSubjectType(
                    evaluationsPendingFiltered[itemSelectedIndex].subject
                  )
                }}
                <p class="font-weight-medium mb-1">
                  {{
                    evaluationsPendingFiltered[itemSelectedIndex].subject?.name
                  }}
                </p>
              </span>
              <span class="font-weight-bold">
                Descripción:
                <p class="font-weight-medium cut-text mb-1">
                  {{
                    evaluationsPendingFiltered[itemSelectedIndex]?.description
                  }}
                </p>
              </span>
              <div>
                <span class="font-weight-bold"> Etapa: </span>
                <p class="font-weight-medium">
                  {{ evaluationsPendingFiltered[itemSelectedIndex]?.stage }}
                </p>
              </div>
              <span class="font-weight-bold d-flex">
                Porcentaje:
                <p class="font-weight-medium ml-1 mb-1">
                  {{ evaluationsPendingFiltered[itemSelectedIndex]?.percent }}%
                </p>
              </span>
            </v-col>
            <!-- Finaliza: Información de la subevaluación-->
            <!-- Inicia: Detalles de la subevaluación -->
            <v-col cols="12" md="5" class="pt-0 pl-3 pl-md-0 pl-lg-0">
              <span class="font-weight-bold d-flex">
                Fecha limite:
                <p class="font-weight-medium ml-1 mb-1">
                  {{
                    evaluationsPendingFiltered[itemSelectedIndex]
                      ?.limit_date_formatted
                  }}
                </p>
              </span>
              <span class="font-weight-bold d-flex">
                Días atrasados:
                <p class="font-weight-medium ml-1 mb-1">
                  {{
                    evaluationsPendingFiltered[itemSelectedIndex]?.days_delay
                  }}
                </p>
              </span>
              <span class="font-weight-bold d-flex">
                {{
                  evaluationsPendingFiltered[itemSelectedIndex]?.subject
                    ?.speciality_id !== 8
                    ? "Grupos "
                    : "Secciones"
                }}
                sin nota:
              </span>
              <!-- Secciones o grupos sin nota -->
              <v-container class="px-0 pt-1">
                <v-chip
                  class="my-1 mr-1 text-subtitle-1"
                  v-for="sectionMissing in evaluationPending.data[
                    itemSelectedIndex
                  ]?.subjectCount"
                  :key="sectionMissing.key"
                  :color="evaluationsPendingFiltered[itemSelectedIndex].color"
                >
                  <span class="mr-1 font-weight-medium">{{
                    `${sectionMissing.name}:`
                  }}</span>
                  {{ `${sectionMissing.count} Pendientes` }}
                </v-chip>
              </v-container>
            </v-col>
            <!-- Finaliza: Detalles de la subevaluación -->
            <v-col cols="12" class="pt-0 px-0">
              <v-btn
                :color="evaluationsPendingFiltered[itemSelectedIndex].color"
                block
                @click="
                  goToScores(
                    evaluationsPendingFiltered[itemSelectedIndex].subject.id,
                    evaluationsPendingFiltered[itemSelectedIndex].evaluation_id
                  )
                "
                >Ir a la actividad</v-btn
              >
            </v-col>
          </v-row>
          <!-- Finaliza contenido para docentes-->

          <!-- Inicia contenido para coordinaciones -->
          <div
            class="px-10 mx-0 mb-4"
            v-for="subEvaluation in evaluationsPendingFiltered[
              itemSelectedIndex
            ].subEvaluations"
            :key="subEvaluation.key"
          >
            <v-card class="px-4 py-3">
              <v-row class="mx-0 mt-2" v-if="isCoordination">
                <v-col cols="12" md="7" class="pt-0 pl-md-0 pl-lg-0">
                  <!-- Especialidad (Solo técnicos) -->
                  <span
                    class="font-weight-bold"
                    v-if="subEvaluation.subject.speciality_id !== 8"
                  >
                    Especialidad
                    <p class="font-weight-medium mb-0">
                      {{ subEvaluation.subject.speciality }}
                      <v-chip
                        class="ml-1 text-subtitle-1 font-weight-medium"
                        :color="
                          evaluationsPendingFiltered[itemSelectedIndex].color
                        "
                      >
                        {{ subEvaluation.subject?.grade }}
                      </v-chip>
                    </p>
                  </span>
                  <!-- Módulo (Solo para técnicos) -->
                  <span class="font-weight-bold">
                    {{ setSubjectType(subEvaluation.subject) }}
                    <p class="font-weight-medium mb-0">
                      {{ subEvaluation.subject?.name }}
                      <v-chip
                        class="ml-1 text-subtitle-1 font-weight-medium"
                        :color="
                          evaluationsPendingFiltered[itemSelectedIndex].color
                        "
                      >
                        {{ subEvaluation.subject?.code }}
                      </v-chip>
                    </p>
                  </span>
                  <div>
                    <span class="font-weight-bold"> Etapa: </span>
                    <p class="font-weight-medium">
                      {{ subEvaluation?.stage }}
                    </p>
                  </div>
                  <!-- Tipo de actividad -->
                  <span class="font-weight-bold">
                    Evaluación:

                    <p class="font-weight-medium mb-1">
                      {{ subEvaluation.evaluation_type }}
                      <v-chip
                        class="ml-1 text-subtitle-1 font-weight-medium"
                        :color="
                          evaluationsPendingFiltered[itemSelectedIndex].color
                        "
                      >
                        {{ subEvaluation.activity_type }}
                      </v-chip>
                    </p>
                  </span>
                  <!-- Descripción -->
                  <span class="font-weight-bold">
                    Descripción:
                    <p class="font-weight-medium cut-text mb-1">
                      {{ subEvaluation.description }}
                    </p>
                  </span>
                  <!-- Porcentaje -->
                  <span class="font-weight-bold d-flex" v-if="isTeacher">
                    Porcentaje:
                    <p class="font-weight-medium ml-1 mb-1">
                      {{ subEvaluation.percent }}%
                    </p>
                  </span>
                </v-col>
                <!-- Fin Información de la subevaluación -->
                <!-- Inicio: Detalles adicionales y división de notas pendientes  -->
                <v-col cols="12" md="5" class="pt-0 pl-3 pl-md-0 pl-lg-0">
                  <span class="font-weight-bold d-flex">
                    Fecha limite:
                    <p class="font-weight-medium ml-1 mb-1">
                      {{ subEvaluation.limit_date_formatted }}
                    </p>
                  </span>
                  <span class="font-weight-bold d-flex" v-if="isCoordination">
                    Porcentaje:
                    <p class="font-weight-medium ml-1 mb-1">
                      {{ subEvaluation.percent }}%
                    </p>
                  </span>
                  <span class="font-weight-bold d-flex">
                    Días atrasados:
                    <p class="font-weight-medium ml-1 mb-1">
                      {{ subEvaluation.days_delay }}
                    </p>
                  </span>
                  <span class="font-weight-bold d-flex">
                    Estudiantes sin nota:
                    <p class="font-weight-medium ml-1 mb-1">
                      {{ subEvaluation.count }}
                    </p>
                  </span>
                  <span class="font-weight-bold d-flex">
                    {{
                      evaluationsPendingFiltered[itemSelectedIndex]?.subject
                        ?.speciality_id !== 8
                        ? "Grupos "
                        : "Secciones"
                    }}
                    sin nota:
                  </span>
                  <!-- Secciones o grupos sin nota -->
                  <v-container class="px-0 pt-1">
                    <v-chip
                      class="my-1 mr-1 text-subtitle-1"
                      v-for="sectionMissing in subEvaluation.subjectCount"
                      :key="sectionMissing.key"
                      :color="`${evaluationsPendingFiltered[itemSelectedIndex].color} lighten-4`"
                    >
                      <span class="mr-1 font-weight-medium">{{
                        `${sectionMissing.name}:`
                      }}</span>
                      {{ `${sectionMissing.count} Pendientes` }}
                    </v-chip>
                  </v-container>
                </v-col>
                <!-- Fin: Detalles adicionales y división de notas pendientes -->
              </v-row>
            </v-card>
          </div>

          <!-- Finaliza contenido para coordinaciones -->
        </v-sheet>
      </v-expand-transition>
    </v-sheet>
  </div>
</template>

<script>
import dayjs from "dayjs";
import subEvaluationPending from "@/repositories/subEvaluationPendingRepository";

import { mapGetters } from "vuex";
import {
  GET_CURRENT_PAGE_ACTIONS,
  PURGE_CURRENT_PAGE_ACTIONS,
} from "@/core/services/store/role.module";

import LoadingComponent from "../justifications/LoadingComponent.vue";
import IconCard from "../dashboard/IconCard.vue";

export default {
  name: "SubEvaluationPendingWidget",
  components: {
    LoadingComponent,
    IconCard,
  },
  data() {
    return {
      showArrow: true,
      evaluationPending: {
        data: [],
        isLoading: false,
      },
      evaluationsPendingFiltered: [],
      wrongStatus: false,
      itemSelectedIndex: null,
      teacherFilterText: "",
    };
  },
  methods: {
    formatDate(date) {
      return dayjs(date).format("DD-MM-YYYY");
    },
    async getSubEvaluationPendingByUser() {
      this.evaluationPending.isLoading = true;
      // Si no se ha identificado el tipo de usuarios
      if (!this.isTeacher && !this.isCoordination) {
        this.wrongStatus = true;
        return;
      }
      // Se carga el contenido dependiendo del permiso
      if (this.isTeacher) {
        subEvaluationPending
          .getSubEvaluationPendingByUser(this.currentUserPersonalInfo.id_user)
          .then(({ data }) => {
            if (data?.subjects_teacher) {
              const { user_information, subjects_teacher } = data;
              const evaluationsPending = [];
              subjects_teacher.forEach((subjectAndEvaluation) => {
                const { subject, evaluations } = subjectAndEvaluation;
                evaluations.forEach((evaluation) => {
                  // Obtener las evaluaciones pendientes
                  evaluation.sub_evaluations.forEach((evaluationPending) => {
                    // Agrega el resto de campos a mostrar por evaluación
                    evaluationPending.user_information = user_information;
                    evaluationPending.subject = subject;
                    evaluationPending.stage = evaluation.stage;
                    // Dependiendo de la materia, se colocan los grupos académicos o técnicos
                    if (subject.speciality_id === 8) {
                      evaluationPending.subjectCount =
                        evaluationPending.academic_groups;
                    } else {
                      evaluationPending.subjectCount = [];
                      // Correcciones para mostrar el grupo y sección
                      evaluationPending.technical_groups.forEach((tecnical) => {
                        const technicalGroupsWithSection =
                          tecnical.academic_groups_by_technical_group.map(
                            (tecnicalGroup) => {
                              tecnicalGroup.name = `${tecnical.name}-${tecnicalGroup.name}`;
                              return tecnicalGroup;
                            }
                          );
                        evaluationPending.subjectCount =
                          evaluationPending.subjectCount.concat(
                            technicalGroupsWithSection
                          );
                      });
                    }
                    // Agrega el color
                    evaluationPending.color = "gray lighten-4";
                    if (subject.speciality_id === 8) {
                      evaluationPending.color = "orange lighten-4";
                    }
                    if (subject.speciality_id !== 8) {
                      evaluationPending.color = "blue lighten-4";
                    }
                    evaluationsPending.push(evaluationPending);
                  });

                  // Anexa todas las evaluciones
                  this.evaluationPending.data = evaluationsPending;
                });
              });
            }
            // Se agrega en el filtrador
            this.evaluationsPendingFiltered = this.evaluationPending.data;
          })
          .catch((err) => {
            console.log(err.message);
            this.fireToast({
              icon: "error",
              title: "Ocurrió un error al obtener las evaluaciones pendientes",
            });
            this.wrongStatus = true;
          })
          .finally(() => {
            this.evaluationPending.isLoading = false;
          });
      }

      // Contenido para coordinaciones
      if (this.isCoordination) {
        subEvaluationPending
          .getAllEvaluationPending()
          .then(({ data }) => {
            const subEvaluationsComplete = data.map((completeObject) => {
              const { subjects_teacher } = completeObject;
              // Un subject_teacher por cada módulo o asignatura del docente
              let mainSubject;
              let totalCount = 0;
              let subEvaluations = [];
              subjects_teacher.forEach((subjectTeacher) => {
                const { subject, evaluations } = subjectTeacher;
                mainSubject = subject;
                // Se recorren las evaluaciones y subevaluaciones
                evaluations.forEach((subEvaluation) => {
                  const { sub_evaluations, stage } = subEvaluation;
                  
                  const subEvaluationArray = sub_evaluations.map(
                    (subEvaluationDetail) => {
                      // Se cargan el contenido directamente
                      if (subject.speciality_id === 8) {
                        subEvaluationDetail.subjectCount =
                          subEvaluationDetail.academic_groups;
                      } else {
                        // Se transforma el contenido
                        subEvaluationDetail.subjectCount = [];
                        // Correcciones para mostrar el grupo y sección
                        subEvaluationDetail.technical_groups.forEach(
                          (tecnical) => {
                            const technicalGroupsWithSection =
                              tecnical.academic_groups_by_technical_group.map(
                                (tecnicalGroup) => {
                                  tecnicalGroup.name = `${tecnical.name}-${tecnicalGroup.name}`;
                                  return tecnicalGroup;
                                }
                              );
                            subEvaluationDetail.subjectCount =
                              subEvaluationDetail.subjectCount.concat(
                                technicalGroupsWithSection
                              );
                          }
                        );
                      }
                      // Agrega al contador general
                      totalCount = totalCount + subEvaluationDetail.count;
                      // Inclusión de la materia por si es sociales
                      subEvaluationDetail.subject = subject;
                      subEvaluationDetail.stage = stage;
                      return subEvaluationDetail;
                    }
                  );
                  // Se anexan los subevaluations
                  subEvaluations = subEvaluations.concat(subEvaluationArray);
                });
                // Se carga el contenido obtenido
                completeObject.subject = mainSubject;
                completeObject.subEvaluations = subEvaluations;
                completeObject.count = totalCount;
                // Agrega el color
                completeObject.color = "gray lighten-4";
                if (subject.speciality_id === 8) {
                  completeObject.color = "orange lighten-4";
                }
                if (subject.speciality_id !== 8) {
                  completeObject.color = "blue lighten-4";
                }
              });
              return completeObject;
            });
            // Filtra los datos
            this.evaluationPending.data = subEvaluationsComplete.filter(
              (evaluation) => {
                const coordinations = [
                  "Coordinación académica",
                  "Coordinación técnica",
                ];
                // Si no es una coordinación, los devuelve todos
                if (!coordinations.includes(this.userType?.user_type)) {
                  return evaluation;
                }
                // Coordinación académica
                if (
                  this.userType?.user_type == "Coordinación académica" &&
                  evaluation.subject.speciality_id === 8
                ) {
                  return evaluation;
                }

                // Coordinación técnica
                if (
                  this.userType?.user_type == "Coordinación técnica" &&
                  evaluation.subject.speciality_id !== 8
                ) {
                  return evaluation;
                }
              }
            );
            // Se agrega en el filtrador
            this.evaluationsPendingFiltered = this.evaluationPending.data;
          })
          .catch((err) => {
            console.log(err);
            this.fireToast({
              icon: "error",
              title: "Ocurrió un error al obtener las evaluaciones pendientes",
            });
            this.wrongStatus = true;
          })
          .finally(() => {
            this.evaluationPending.isLoading = false;
          });
      }
    },
    updateShowArrows() {
      this.showArrow = this.getWidthWindow() > 600;
    },
    getWidthWindow() {
      if (self.innerWidth) {
        return self.innerWidth;
      }
      if (document.documentElement && document.documentElement.clientWidth) {
        return document.documentElement.clientWidth;
      }
      if (document.body) {
        return document.body.clientWidth;
      }
    },
    goToScores(subject, evaluation) {
      this.$router.push({
        path: "evaluation",
        query: {
          // s de subject, se guarda el id de la asignatura
          s: subject,
          // e de evaluation se guarda el id de la evaluación
          e: evaluation,
          // f de from, se especifica desde la vista que viene
          f: "subject_evaluations",
        },
      });
    },
    setCardTitle(subject) {
      if (subject?.speciality_id === 8) {
        return subject.name;
      }
      return subject?.speciality;
    },
    setSubjectType(subject) {
      if (subject?.speciality_id === 8) {
        return "Asignatura:";
      }
      return "Módulo:";
    },
    searchTeacher() {
      const searchTextFormatted = this.teacherFilterText
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
      const dataFiltered = this.evaluationPending.data.filter((evaluation) => {
        const teacherNameFormatted =
          `${evaluation.user_information.first_name} ${evaluation.user_information.last_name}`
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "");
        if (
          teacherNameFormatted.includes(searchTextFormatted) ||
          evaluation.user_information.email.includes(searchTextFormatted) ||
          searchTextFormatted === ""
        ) {
          return evaluation;
        }
      });
      this.evaluationsPendingFiltered = dataFiltered;
    },
  },
  computed: {
    ...mapGetters([
      "currentUserPersonalInfo",
      "currentPageActions",
      "userType",
    ]),
    emptyEvaluations() {
      return (
        !this.evaluationPending.isLoading &&
        this.evaluationPending.data.length == 0 &&
        !this.wrongStatus
      );
    },
    isTeacher() {
      return this.existInArray("Widget para docentes", this.currentPageActions);
    },
    isCoordination() {
      return this.existInArray(
        "Widget para coordinaciones",
        this.currentPageActions
      );
    },
  },
  mounted() {
    window.addEventListener("resize", this.updateShowArrows);
    this.updateShowArrows();
    // Carga el contenido
    this.getSubEvaluationPendingByUser();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateShowArrows);
    this.$store.commit(PURGE_CURRENT_PAGE_ACTIONS);
  },
};
</script>

<style scoped>
.cut-text {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  position: relative;
}

.custom-item {
  width: 50%;
  margin: 10px 12px;
}
</style>